import React from 'react'
import { EventRegistrationPage } from './_regPage'

export default function BookstoreEventPage({
  pageContext: { id, title, subText, dateText },
}) {
  return (
    <EventRegistrationPage
      title={title}
      subtitle={subText}
      date={dateText}
      eventId={id}
      variant={'bookstore'}
    />
  )
}
